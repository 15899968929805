import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Medium`}</h3>
    <p>{`I am write in medium about Learning Curve in Technology`}</p>
    <p><a parentName="p" {...{
        "href": "https://medium.com/@RFebrians"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/Medium-12100E?style=for-the-badge&logo=medium&logoColor=white",
          "alt": "Medium"
        }}></img></a></p>
    <h3>{`Wikimedia`}</h3>
    <p>{`I am write in Wikimedia Network like Wikipedia and Wikisource`}</p>
    <p><a parentName="p" {...{
        "href": "https://id.wikisource.org/wiki/Pengguna:Zegveld"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/Wikipedia-%23000000.svg?style=for-the-badge&logo=wikipedia&logoColor=white",
          "alt": "Wikipedia"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://id.wikisource.org/wiki/Pengguna:Zegveld"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/Wikisource-%23000000.svg?style=for-the-badge&logo=wikipedia&logoColor=grey",
          "alt": "Wikipedia"
        }}></img></a></p>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      